import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { getSelectedPeriodInfo } from "@core/period-info"
import get from "lodash/get"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import { PriceVariantType } from "@onestore/hel/dist/components/molecules/PriceMol/priceMol.types"
import RadioBoxMol from "@onestore/hel/dist/components/molecules/RadioBoxMol"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import DetailedPrice from "@gatsby-plugin-generic-page/components/DetailedPrice"
import type { ConfigDrawerPeriod } from "@gatsby-plugin-generic-page/fragments/configDrawerPeriod"
import getBackground from "@gatsby-plugin-generic-page/helpers/getBackground"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import { sendConfigDrawerPeriodBoxClickEvent } from "~/lib/analytics"
import isEmpty from "~/lib/isEmpty"
import { getSavingPrice } from "../../helpers/getSavingPrice"
import { TopLabel } from "../TopLabel"

export type RadioBoxProps = {
  planData: CloudBluePlan["flatData"]
  configPeriod: ConfigDrawerPeriod
  activePeriodName: string
  setIsActivePeriodName: (value: string) => void
  allConfigPeriodNames: string[]
}

export function RadioBox({
  planData,
  configPeriod,
  activePeriodName,
  setIsActivePeriodName,
  allConfigPeriodNames,
}: RadioBoxProps): ReactElement<RadioBoxProps> | null {
  const { isNettoPriceType } = usePriceTypeContext()
  const { period, hasTopLabel, topLabel } = configPeriod

  const boxPeriodName = period.period
  const boxProductName = planData.name

  const periodInfo = getSelectedPeriodInfo(planData.periods, boxPeriodName)

  if (isEmpty(periodInfo)) {
    return null
  }

  const handleOnClick = () => {
    setIsActivePeriodName(boxPeriodName)
    sendConfigDrawerPeriodBoxClickEvent(boxPeriodName, boxProductName)
  }

  const savingPrice = getSavingPrice(
    planData,
    periodInfo,
    allConfigPeriodNames,
    isNettoPriceType
  )

  const topLabelText = get(configPeriod, "topLabel[0].flatData.text")
  const topLabelBackgroundData = get(
    configPeriod,
    "topLabel[0].flatData.backgroundColor"
  )
  const topLabelBackground = getBackground(topLabelBackgroundData)

  const topLabelElement =
    hasTopLabel && (!isEmpty(savingPrice) || !isEmpty(topLabelText)) ? (
      <TopLabel data={topLabel} savingPrice={savingPrice} />
    ) : undefined

  const hasPromotion = periodInfo.hasPromotion()

  const promoLabel = (
    <LabelAtm variant="primaryOutlined" size="small">
      {_t("marketplace.discountTitle")}
    </LabelAtm>
  )

  const isChecked = boxPeriodName === activePeriodName

  const radioTextElement = (
    <TextAtm typography="medium3_h">
      {periodInfo.getPeriodBasketLabel(false)}
    </TextAtm>
  )

  const priceElement = (
    <DetailedPrice
      variant={{
        small: {
          type: PriceVariantType.SMALL,
          align: "left",
        },
        medium: {
          type: PriceVariantType.SMALL,
          align: "right",
        },
        large: {
          type: PriceVariantType.SMALL,
          align: "right",
        },
      }}
      periodInfo={periodInfo}
      priceInfo={planData.priceInfo}
      hasHiddenBadge
      showPriceInfoLabelsOnlyOnDefaultPeriod
    />
  )

  return (
    <RadioBoxMol
      isChecked={isChecked}
      text={radioTextElement}
      aside={priceElement}
      topLabel={!isEmpty(topLabelElement) ? topLabelElement : undefined}
      topLabelBackgroundColor={
        topLabelBackground.themeAccentColor ||
        topLabelBackground.backgroundThemeColor
      }
      bottomLabel={hasPromotion ? promoLabel : null}
      onChange={handleOnClick}
    />
  )
}
