import _t from "@core/i18n"
import { AxiosError } from "axios"
import { navigate } from "gatsby"
import Cookies from "js-cookie"
import type { ThunkAction } from "redux-thunk"
import type {
  CompanyData,
  PersonData,
  UserResponse,
} from "@onestore/api/account"
import type {
  Basket,
  BasketParamListItem,
  BasketParamsResponse,
} from "@onestore/api/basket"
import type {
  JSONPatch,
  OrderResponse,
  TermResponse,
} from "@onestore/api/types"
import { TermType } from "@onestore/api/types"
import type {
  BasketState,
  CheckoutState,
  HTTP,
} from "@onestore/onestore-store-common"
import { LegacyApiSingleErrors } from "@onestore/onestore-store-common/api"
import {
  HTTP_STATUS,
  isResponsePending,
} from "@onestore/onestore-store-common/http"
import { BasketParamsCollection } from "@onestore/onestore-store-common/params"
import {
  BasketActionSource,
  getBasket,
} from "@gatsby-plugin-basket/store/actions"
import {
  getFlatBasketViewItemsWithLinkedHook,
  hasTransferInBasket,
} from "@gatsby-plugin-basket/store/selectors"
import type { OldOrderInfo } from "@gatsby-plugin-checkout/hooks/useOldOrderInfo"
import { handleCeneoOpinionsTracking } from "@gatsby-plugin-checkout/lib/ceneo"
import { getTermsByType } from "@gatsby-plugin-checkout/lib/terms"
import StoreAPI from "~/lib/api"
import { forceRedirectToIndex } from "~/lib/browser"
import {
  getAffiliateCookieName,
  getCountryCode,
  getStoreContext,
  isSessionEnabled,
} from "~/lib/config"
import log from "~/lib/log"
import Storage, { STORAGE } from "~/lib/storage"
import LocalStorage from "~/lib/storage/LocalStorage"
import SessionStorage from "~/lib/storage/SessionStorage"
import url from "~/lib/url"
import { saveSession } from "~/store/account/actions"
import { getAccount } from "~/store/account/selectors"
import {
  addRequestErrorGlobalMessage,
  removeAllGlobalMessages,
} from "~/store/messages/actions"
import type { AppDispatch, AppState } from "~/store/reducer"
import { CheckoutActions, COOKIES } from "./constants"

interface ResponseStatusAction<Type> {
  type: Type
  response_status_code: HTTP.Status
}

interface ParamsGetAction<Type> extends ResponseStatusAction<Type> {
  params: Record<string, BasketParamListItem>
}

interface AlternativeResponseStatusAction<Type> {
  type: Type
  status: HTTP.Status
}

interface AuthSuccessAction<Type>
  extends AlternativeResponseStatusAction<Type> {
  login: string
  accountExists: boolean
}

interface SimpleAction<Type> {
  type: Type
}

export interface AddressConfirmationOpenAction {
  type: CheckoutActions.ADDRESS_CONFIRMATION_OPEN
  user: CompanyData | PersonData
}

interface AuthLoginSaveAction<Type> extends SimpleAction<Type> {
  login: string
  accountExists: boolean
  checkUserRequest: HTTP.Status
  authUserRequest: HTTP.Status
}

interface PaymentMethodAction {
  type: CheckoutActions.PAYMENT_PENDING
  payment_method?: number | null
}

interface UserRegisterSuccessAction {
  type: CheckoutActions.USER_REGISTER_SUCCESS
  result: CompanyData | PersonData
  response_status_code: HTTP.Status
}

interface UserUpdateSuccessAction {
  type: CheckoutActions.USER_UPDATE_SUCCESS
  result: CompanyData | PersonData
  response_status_code: HTTP.Status
}

interface UserSuccessAction {
  type: CheckoutActions.USER_SUCCESS
  response_status_code: HTTP.Status
  result: UserResponse
  is_after_login: boolean
}

interface BasketTermsSuccessAction {
  type: CheckoutActions.BASKET_TERMS_SUCCESS
  response_status_code: HTTP.Status
  result: TermResponse
}

interface BasketToggleTermAction {
  type: CheckoutActions.BASKET_TERMS_TOGGLE_TERM
  id: number
  termType: TermType
}

export interface PaymentSuccessAction {
  type: CheckoutActions.PAYMENT_SUCCESS
  status: HTTP.Status
  result: OrderResponse
  paymentMethods: BasketState["paymentMethods"]
  paymentMethod: BasketState["paymentMethod"]
  basket: BasketState
}
interface TermsValidationAction {
  type: CheckoutActions.BASKET_TERMS_VALIDATION
  value: boolean | undefined
}
export type CheckoutAction =
  | TermsValidationAction
  | PaymentSuccessAction
  | BasketToggleTermAction
  | BasketTermsSuccessAction
  | PaymentMethodAction
  | UserSuccessAction
  | AuthLoginSaveAction<CheckoutActions.AUTH_LOGIN_RESET>
  | AuthLoginSaveAction<CheckoutActions.AUTH_LOGIN_SKIP>
  | AlternativeResponseStatusAction<CheckoutActions.AUTH_PENDING>
  | AlternativeResponseStatusAction<CheckoutActions.AUTH_FAILURE>
  | ParamsGetAction<CheckoutActions.BASKET_PARAMS_GET_SUCCESS>
  | AuthSuccessAction<CheckoutActions.AUTH_SUCCESS>
  | SimpleAction<CheckoutActions.BASKET_TERMS_TOGGLE_ALL_TERMS>
  | SimpleAction<CheckoutActions.CHECKOUT_RESET>
  | SimpleAction<CheckoutActions.BASKET_TERMS_PENDING>
  | SimpleAction<CheckoutActions.RESET>
  | SimpleAction<CheckoutActions.BASKET_SUCCESS>
  | SimpleAction<CheckoutActions.USER_PENDING>
  | SimpleAction<CheckoutActions.BASKET_PENDING>
  | SimpleAction<CheckoutActions.BASKET_PARAMS_GET_PENDING>
  | SimpleAction<CheckoutActions.BASKET_PARAMS_SAVE_PENDING>
  | SimpleAction<CheckoutActions.BASKET_FAILURE>
  | SimpleAction<CheckoutActions.PAYMENT_FAILURE>
  | SimpleAction<CheckoutActions.ADDRESS_CONFIRMATION_CLOSE>
  | SimpleAction<CheckoutActions.USER_EDIT_DATA_MODAL_CLOSE>
  | SimpleAction<CheckoutActions.USER_EDIT_DATA_MODAL_OPEN>
  | AddressConfirmationOpenAction
  | ResponseStatusAction<CheckoutActions.BASKET_PARAMS_SAVE_SUCCESS>
  | ResponseStatusAction<CheckoutActions.BASKET_PARAMS_SAVE_FAILURE>
  | ResponseStatusAction<CheckoutActions.BASKET_TERMS_FAILURE>
  | ResponseStatusAction<CheckoutActions.ORDER_FAILURE>
  | SimpleAction<CheckoutActions.ORDER_PENDING>
  | ResponseStatusAction<CheckoutActions.ORDER_SUCCESS>
  | UserRegisterSuccessAction
  | UserUpdateSuccessAction
  | ResponseStatusAction<CheckoutActions.USER_FAILURE>
  | ResponseStatusAction<CheckoutActions.BASKET_PARAMS_GET_FAILURE>

export type CheckoutThunkAction = ThunkAction<
  void,
  AppState,
  undefined,
  CheckoutAction
>

export const getBasketTerms = () => {
  return (
    dispatch: AppDispatch<CheckoutThunkAction>,
    getState: { (): AppState }
  ) => {
    const state = getState()

    if (isResponsePending(state.basket.terms_request)) {
      return
    }

    dispatch({
      type: CheckoutActions.BASKET_TERMS_PENDING,
    })

    const token = state.basket.token

    if (!token) {
      forceRedirectToIndex()
    }

    return StoreAPI.getTerms(token)
      .catch(() => {
        dispatch({
          type: CheckoutActions.BASKET_TERMS_FAILURE,
          response_status_code: HTTP_STATUS.INTERNAL_SERVER_ERROR,
        })
      })
      .then((result) => {
        if (undefined === result) {
          return
        }

        dispatch({
          type: CheckoutActions.BASKET_TERMS_SUCCESS,
          response_status_code: HTTP_STATUS.OK,
          result,
        })
      })
  }
}

export const getBasketParams = () => {
  return (
    dispatch: AppDispatch<CheckoutThunkAction>,
    getState: { (): AppState }
  ) => {
    const state = getState()

    if (isResponsePending(state.basket.get_params_request)) {
      return
    }
    dispatch({
      type: CheckoutActions.BASKET_PARAMS_GET_PENDING,
    })

    const token = state.basket.token

    if (!token) {
      forceRedirectToIndex()
    }

    return StoreAPI.getBasketParams(token)
      .catch(() => {
        dispatch({
          type: CheckoutActions.BASKET_PARAMS_GET_FAILURE,
          response_status_code: HTTP_STATUS.INTERNAL_SERVER_ERROR,
        })
      })
      .then((result: BasketParamsResponse) => {
        dispatch({
          type: CheckoutActions.BASKET_PARAMS_GET_SUCCESS,
          response_status_code: HTTP_STATUS.OK,
          params: BasketParamsCollection(result),
        })
      })
  }
}

export function toggleAllTerms() {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    dispatch({
      type: CheckoutActions.BASKET_TERMS_TOGGLE_ALL_TERMS,
    })
  }
}

export function toggleTerm(type, id) {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    dispatch({
      type: CheckoutActions.BASKET_TERMS_TOGGLE_TERM,
      termType: type,
      id,
    })
  }
}

export function reset() {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    dispatch({
      type: CheckoutActions.RESET,
    })
  }
}

export function handleSuccessfullLogin(dispatch, login) {
  LocalStorage.setBool(STORAGE.LOGIN_EXISTS, true)
  LocalStorage.set(STORAGE.USER_LOGIN, login)

  dispatch(
    savePersonData({
      person_details: {
        country: getCountryCode(),
      },
      credentials: {
        email: login,
      },
    })
  )
  dispatch(
    saveCompanyData({
      company_details: {
        country: getCountryCode(),
      },
      credentials: {
        email: login,
      },
    })
  )

  dispatch(getUser(true))
}

export function resetUserLogin() {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    LocalStorage.remove(STORAGE.USER_LOGIN)
    LocalStorage.remove(STORAGE.LOGIN_EXISTS)

    dispatch({
      type: CheckoutActions.AUTH_LOGIN_RESET,
      login: null,
      accountExists: false,
      isAuthenticated: false,
      status: HTTP_STATUS.NONE,
    })
  }
}

export function skipLoginForm() {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    dispatch({
      type: CheckoutActions.AUTH_LOGIN_SKIP,
      login: "",
      accountExists: false,
      isAuthenticated: false,
    })
  }
}

/**
 * Przetwarzanie odpowiedzi z API zawierającej informacje o złożonym zamówieniu.
 * Obsługuje ustawianie ciasteczka z typami produktów które zamówił klient.
 * ustawia dane które muszą zostać zapisane przed dokonaniem przekierowania do metody płatności
 */
function handleOrderResponse(
  order: OrderResponse,
  checkout: CheckoutState,
  basket: BasketState
) {
  const orderInfo: OldOrderInfo = {
    token: order.token,
    orderNumber: order.number,
    orderId: order.id,
    email: checkout.email ?? "",
    hasTransfer: hasTransferInBasket(basket),
    orderTotal: order.total_value,
    items: basket.items.map((item) => ({
      periodId: item.plan_period_id,
      planId: item.plan_id,
      quantity: 1,
    })),
  }

  LocalStorage.setSerialized(STORAGE.OLD_ORDER, orderInfo)

  LocalStorage.remove(STORAGE.TOKEN_NAME)
  SessionStorage.set(
    STORAGE.CONTROL_PANEL,
    order.control_panel_url || undefined
  )
}

/**
 * Metoda obsługuje składanie zamówienia.
 *
 * @param {string} method wybrana metoda płatności
 * @returns {null}
 */
export function checkoutMake(terms, ceneoFeedPlansIds: number[]) {
  return (
    dispatch: AppDispatch<CheckoutThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: CheckoutActions.PAYMENT_PENDING,
      response_status_code: HTTP_STATUS.CONTINUE,
    })
    const state = getState()
    const token = state.basket.token

    if (!token) {
      forceRedirectToIndex()

      return
    }

    const data = {
      terms,
      affiliate_code: Cookies.get(getAffiliateCookieName()),
      context: getStoreContext(),
      remember_me: false,
    }

    if (isSessionEnabled() && !getAccount(state)) {
      data.remember_me = Cookies.get(COOKIES.REMEMBER_ME) === "1"
    }

    log(data, "checkout")

    return StoreAPI.checkout(token, data)
      .catch((error: AxiosError<LegacyApiSingleErrors>) => {
        const errorData = StoreAPI.getNormalizedError(error)

        if (HTTP_STATUS.UNAVAILABLE_FOR_LEGAL_REASONS === errorData.status) {
          dispatch(
            addRequestErrorGlobalMessage(
              "Nie wszystkie regulaminy zostały zaakceptowane"
            )
          )

          return
        }

        if (
          HTTP_STATUS.FORBIDDEN === errorData.status &&
          "detail" in errorData &&
          errorData.detail
        ) {
          dispatch(addRequestErrorGlobalMessage(errorData.detail))

          return
        }

        if (HTTP_STATUS.CONFLICT === errorData.status) {
          dispatch(resetCheckoutRequestState())
          dispatch(getBasketSummary(token, true))

          return
        }

        dispatch({
          type: CheckoutActions.PAYMENT_FAILURE,
          response_status_code: HTTP_STATUS.INTERNAL_SERVER_ERROR,
        })

        dispatch(
          addRequestErrorGlobalMessage(_t("checkout.orderErrorMessageBar"))
        )

        if (HTTP_STATUS.NOT_FOUND === errorData.status) {
          navigate(url.getFailureUrl())

          return
        }
      })
      .then(async (result) => {
        if (undefined === result) {
          return
        }

        try {
          handleOrderResponse(result.order, state.checkout, state.basket)
          handleCeneoOpinionsTracking(state, result.order, ceneoFeedPlansIds)
        } catch (e) {
          // w przypadku wystąpienia błędu, nie przerywamy procesu składania zamówienia
        }

        Storage.clearSig()
        Cookies.remove(COOKIES.REMEMBER_ME)

        dispatch({
          type: CheckoutActions.PAYMENT_SUCCESS,
          status: HTTP_STATUS.OK,
          result: result.order,
          basket: state.basket,
          paymentMethods: state.basket.paymentMethods,
          paymentMethod: state.basket.paymentMethod,
          basketOrderedItems: getFlatBasketViewItemsWithLinkedHook(state),
        } as PaymentSuccessAction)

        if (isSessionEnabled() && result.session) {
          dispatch(
            saveSession(result.session.id, new Date(result.session.expire_at))
          )
        }
      })
  }
}

export function proceedOrder(ceneoFeedPlansIds: number[]) {
  return (
    dispatch: AppDispatch<CheckoutThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch(removeAllGlobalMessages())

    const state = getState()

    const token = state.basket.token

    if (!token) {
      forceRedirectToIndex()

      return
    }

    const terms = state.checkout.terms

    const validTerms = terms
      .filter((term) => !term.optional)
      .every((term) => term.checked)

    if (validTerms) {
      dispatch({
        type: CheckoutActions.ORDER_PENDING,
        response_status_code: HTTP_STATUS.CONTINUE,
      })
      dispatch(
        checkoutMake(
          {
            external: getTermsByType(TermType.EXTERNAL, terms),
            internal: getTermsByType(TermType.INTERNAL, terms),
          },
          ceneoFeedPlansIds
        )
      )
    } else {
      dispatch({
        type: CheckoutActions.BASKET_TERMS_VALIDATION,
        value: validTerms,
      })
    }
  }
}

export function getUser(isAfterLogin?: boolean) {
  return (
    dispatch: AppDispatch<CheckoutThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: CheckoutActions.USER_PENDING,
    })

    const state = getState()
    const token = state.basket.token

    if (!token) {
      forceRedirectToIndex()

      return
    }

    return StoreAPI.getUser(token)
      .catch(() => {
        dispatch({
          type: CheckoutActions.USER_FAILURE,
          response_status_code: HTTP_STATUS.BAD_REQUEST,
        })
      })
      .then((result) => {
        if (undefined === result) {
          return
        }
        dispatch({
          type: CheckoutActions.USER_SUCCESS,
          response_status_code: HTTP_STATUS.OK,
          result,
          is_after_login: !!isAfterLogin,
        })
      })
  }
}

export function saveBasketParams(operations: JSONPatch[]) {
  return (
    dispatch: AppDispatch<CheckoutThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: CheckoutActions.BASKET_PARAMS_SAVE_PENDING,
    })

    const token = getState().basket.token

    if (!token) {
      forceRedirectToIndex()

      return
    }

    return StoreAPI.basketPatch(token, operations)
      .catch(() => {
        dispatch({
          type: CheckoutActions.BASKET_PARAMS_SAVE_FAILURE,
          response_status_code: HTTP_STATUS.INTERNAL_SERVER_ERROR,
        })
      })
      .then((response) => {
        if (undefined === response) {
          return
        }

        dispatch({
          type: CheckoutActions.BASKET_PARAMS_SAVE_SUCCESS,
          response_status_code: HTTP_STATUS.OK,
        })

        dispatch(getBasket(token, false))
      })
  }
}

export function getBasketSummary(
  token: Basket.Token,
  withBasket: boolean = false
) {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    dispatch(removeAllGlobalMessages())

    if (withBasket) {
      dispatch(getBasket(token, false, BasketActionSource.SUMMARY))
    }

    dispatch(getUser())
    dispatch(getBasketTerms())
  }
}

export function resetCheckoutRequestState() {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    dispatch({
      type: CheckoutActions.CHECKOUT_RESET,
    })
  }
}

export function userDataEditModalOpen() {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    dispatch({
      type: CheckoutActions.USER_EDIT_DATA_MODAL_OPEN,
    })
  }
}

export function userDataEditModalClose() {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    dispatch({
      type: CheckoutActions.USER_EDIT_DATA_MODAL_CLOSE,
    })
  }
}

export function saveCompanyData(data) {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    dispatch({
      type: CheckoutActions.COMPANY_DATA_SAVE,
      data,
    })
  }
}
export function savePersonData(data) {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    dispatch({
      type: CheckoutActions.PERSON_DATA_SAVE,
      data,
    })
  }
}

export function saveMigratedCompanyData(data) {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    dispatch({
      type: CheckoutActions.COMPANY_DATA_SAVE,
      data,
    })
  }
}

export function saveMigratedPersonData(data) {
  return (dispatch: AppDispatch<CheckoutThunkAction>) => {
    dispatch({
      type: CheckoutActions.PERSON_DATA_SAVE,
      data,
    })
  }
}
