import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import type { PriceMolProps } from "@onestore/hel/dist/components/molecules/PriceMol"
import PriceMol from "@onestore/hel/dist/components/molecules/PriceMol"
import {
  getSecondaryPrices,
  getStaticLowestPrice,
  getStaticPrices,
  getStaticRegularPrice,
} from "@gatsby-plugin-generic-page/helpers/staticPrices"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { PriceInfo } from "~/fragments/priceInfo"
import type { StaticPrice } from "~/fragments/staticPrice"
import isEmpty from "~/lib/isEmpty"
import { parsePeriod } from "../../../../../lib/api"
import {
  getAdditionalPriceInformation,
  getMonthlyPriceElement,
} from "../DetailedPrice"

export type DetailedStaticPriceProps = {
  variant: PriceMolProps["variant"]
  staticPrices: StaticPrice[]
  period: string
  customRenewAdditionalInfo?: string
  bundleData?: {
    hasPromotion: boolean
    priceInfo?: PriceInfo
    hidePeriodText?: boolean
  }
}

const DetailedStaticPrice = ({
  variant,
  staticPrices,
  period,
  customRenewAdditionalInfo,
  bundleData,
}: DetailedStaticPriceProps): ReactElement<DetailedStaticPriceProps> | null => {
  const { currentPriceType } = usePriceTypeContext()

  const prices = staticPrices
    ? getStaticPrices(staticPrices, currentPriceType)
    : {}

  if (isEmpty(prices) || isEmpty(prices[period])) {
    return null
  }

  const crossedPrices = staticPrices
    ? getSecondaryPrices(staticPrices, currentPriceType)
    : {}

  const regularPrices = staticPrices
    ? getStaticRegularPrice(staticPrices, currentPriceType)
    : {}

  const lowestPrices = staticPrices
    ? getStaticLowestPrice(staticPrices, currentPriceType)
    : {}

  const monthlyPrice = prices[period].monthlyPrice

  const bundlePriceInfo = !isEmpty(bundleData)
    ? bundleData.priceInfo
    : undefined

  const priceMonthlyElement = !isEmpty(monthlyPrice)
    ? getMonthlyPriceElement(
        monthlyPrice.value,
        currentPriceType,
        bundlePriceInfo?.monthlyPricePrefix
      )
    : null

  const staticAdditionalInformation = !isEmpty(prices[period]?.renewPrice)
    ? `${_t("prices.renewalPrefix")} ${prices[period].renewPrice?.value} ${prices[period].renewPrice?.suffix}`
    : customRenewAdditionalInfo

  const { periodType, periodValue } = parsePeriod(period)

  const bundleAdditionalPriceInformation = !isEmpty(bundlePriceInfo)
    ? getAdditionalPriceInformation(
        bundlePriceInfo,
        currentPriceType,
        prices[period]?.renewPrice?.value,
        periodType,
        periodValue
      )
    : []

  const hasPromotion: boolean = !isEmpty(bundleData)
    ? bundleData.hasPromotion
    : !isEmpty(crossedPrices) &&
      crossedPrices[period] &&
      !isEmpty(regularPrices) &&
      !isEmpty(regularPrices[period]) &&
      !isEmpty(lowestPrices) &&
      !isEmpty(lowestPrices[period])

  const bundlePeriodText =
    !isEmpty(bundleData) && bundleData.hidePeriodText
      ? _t(`prices.${currentPriceType}`)
      : null

  return (
    <PriceMol
      variant={variant}
      mainPrice={{
        currentPrice: prices[period].price.value,
        crossedPrice:
          hasPromotion && !isEmpty(crossedPrices)
            ? crossedPrices[period]
            : undefined,
        periodText: bundlePeriodText || prices[period].price.suffix,
      }}
      badgeText={priceMonthlyElement}
      additionalInformation={
        !isEmpty(bundleAdditionalPriceInformation)
          ? bundleAdditionalPriceInformation
          : [staticAdditionalInformation]
      }
      regularPrice={
        hasPromotion && !isEmpty(regularPrices)
          ? {
              text: _t("prices.regularPriceWithType", {
                priceType: _t(`prices.${currentPriceType}`),
              }),
              price: regularPrices[period].price,
              percent: regularPrices[period].percent,
            }
          : undefined
      }
      lowestPrice={
        hasPromotion && !isEmpty(lowestPrices)
          ? {
              text: _t("prices.lowestPriceWithType", {
                priceType: _t(`prices.${currentPriceType}`),
              }),
              price: lowestPrices[period].price,
              percent: lowestPrices[period].percent,
            }
          : undefined
      }
    />
  )
}

export default DetailedStaticPrice
