import type { ReactElement } from "react"
import { useEffect } from "react"
import { useState } from "react"
import React from "react"
import _t from "@core/i18n"
import get from "lodash/get"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import OverlayContainerOrg, {
  headerTypes,
  sizes,
  startPositions,
} from "@onestore/hel/dist/components/organisms/OverlayContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { ConfigDrawerPeriod } from "@gatsby-plugin-generic-page/fragments/configDrawerPeriod"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import {
  sendConfigDrawerCloseEvent,
  sendToplayerShowEvent,
} from "~/lib/analytics"
import isEmpty from "~/lib/isEmpty"
import { Bundle } from "./components/Bundle"
import { RadioBox } from "./components/RadioBox"
import Summary from "./components/Summary"
import { getDefaultPeriodName } from "./helpers/getDefaultPeriodName"

export type DrawerBundleData = {
  bundlePlan?: CloudBluePlan
  hasChangedDefaultPlan: boolean
  isDefaultChecked?: boolean
  bundleTitleSuffix?: string
  bundleProductName?: string | null
  bundleTooltip?: string | null
  bundleValue?: string
}

export type ConfigDrawerProps = {
  configPeriods: ConfigDrawerPeriod[]
  saleConfiguration: SaleConfiguration
  bundleData?: DrawerBundleData
  onClose: () => void
}

export function ConfigDrawer({
  configPeriods,
  saleConfiguration,
  bundleData,
  onClose,
}: ConfigDrawerProps): ReactElement<ConfigDrawerProps> | null {
  const [activePeriodName, setIsActivePeriodName] = useState<string | null>(
    null
  )
  const [isBundleChecked, setIsBundleChecked] = useState<boolean>(
    bundleData?.isDefaultChecked ?? false
  )

  const mainProductPlan = get(
    saleConfiguration,
    "flatData.defaultCloudBluePlan[0]"
  )

  const mainProductName = get(mainProductPlan, "flatData.name")
  const eventModalName = `drawer_${mainProductName}`

  useEffect(() => {
    sendToplayerShowEvent(eventModalName)
  }, [])

  if (isEmpty(mainProductPlan)) {
    return null
  }

  const defaultPeriodName = getDefaultPeriodName(
    saleConfiguration.flatData,
    mainProductPlan.flatData
  )

  if (isEmpty(defaultPeriodName)) {
    return null
  }

  const currentActivePeriodName = activePeriodName || defaultPeriodName

  const allConfigPeriodNames = configPeriods.map(
    (configPeriod) => configPeriod.period.period
  )

  const periodsElement = (
    <>
      <PushOrg
        topSpace={1}
        bottomSpace={{
          small: 3,
          medium: 2,
          large: 2,
        }}
        hasNoReset
      >
        <TextAtm typography="big4">{mainProductName}</TextAtm>
      </PushOrg>

      {configPeriods.map((configPeriod, index) => (
        <RadioBox
          key={`${mainProductPlan.id}-${index}`}
          planData={mainProductPlan.flatData}
          configPeriod={configPeriod}
          activePeriodName={currentActivePeriodName}
          setIsActivePeriodName={setIsActivePeriodName}
          allConfigPeriodNames={allConfigPeriodNames}
        />
      ))}
    </>
  )

  const bundleElement = !isEmpty(bundleData) ? (
    <Bundle
      activePeriodName={currentActivePeriodName}
      isChecked={isBundleChecked}
      setIsChecked={setIsBundleChecked}
      bundleData={bundleData}
      mainProductPlanData={mainProductPlan.flatData}
    />
  ) : null

  const modalHeaderElement = (
    <TextAtm
      typography={{
        small: "big2",
        medium: "large2",
        large: "large2",
      }}
    >
      {_t("configDrawer.title")}
    </TextAtm>
  )

  const summaryElement = (
    <Summary
      activePeriodName={currentActivePeriodName}
      saleConfiguration={saleConfiguration}
      mainProductPlan={mainProductPlan}
      isBundleChecked={isBundleChecked}
      bundleData={bundleData}
    />
  )

  const handleOnClose = () => {
    onClose()
    sendConfigDrawerCloseEvent(eventModalName)
  }

  return (
    <OverlayContainerOrg
      header={modalHeaderElement}
      headerType={headerTypes.SHADOWED}
      onCloseButtonClick={handleOnClose}
      startPosition={startPositions.RIGHT}
      size={sizes.MEDIUM}
      footerBar={bundleElement}
      footer={summaryElement}
      footerHorizontalSpace={{
        small: 4,
        medium: 8,
        large: 8,
      }}
    >
      <PushOrg
        leftSpace={{
          small: 2,
          medium: 0,
          large: 0,
        }}
        rightSpace={{
          small: 2,
          medium: 0,
          large: 0,
        }}
        hasNoReset
      >
        {periodsElement}
      </PushOrg>
    </OverlayContainerOrg>
  )
}
