import { getPeriodNameFromPeriodId, convertToMonths } from "@core/period-info"
import { getBasketProductsPE, getCurrencyCode } from "@core/pricing"
import type { BasketResponse } from "@onestore/api/basket"
import type {
  BasketProductItem,
  BasketProductsPE,
  BasketUpdateItem,
} from "@onestore/onestore-store-common"
import { BasketActions } from "@gatsby-plugin-basket/store/constants"
import log from "~/lib/log"
import type { CardItem } from "./dataLayer"
import isEmpty from "./isEmpty"

export enum OAOBasketResult {
  SUCCESS = "SUCCESS",
  INVALID = "INVALID",
  ERROR = "ERROR",
}

export enum OAOApplicationType {
  SHOP = "SHOP",
  SALESTOOL = "SALESTOOL",
}

export enum OAOVoucherType {
  ABSOLUTE = "ABSOLUTE",
  PERCENTAGE = "PERCENTAGE",
}

export enum OAOEventType {
  ADD_ITEM = "addBasketItem",
  REMOVE_ITEM = "removeBasketItem",
  CHANGE_QUANTITY = "changeBasketItemQuantity",
  CHANGE_TERM = "changeBasketItemTerm",
  ADD_VOUCHER = "addVoucherToBasket",
  REMOVE_VOUCHER = "removeVoucherFromBasket",
  ORDER_BASKET = "orderBasket",
  EMPTY_BASKET = "emptyBasket",
}

// All possible basket actions for OAO events data objects
export type OAOBasketItem =
  | OAOAddItem
  | OAORemoveItem
  | OAOChangeQuantity
  | OAOChangeTerm
  | OAOAddVoucher
  | OAORemoveVoucher
  | OAOEmptyOrOrderBasket

/*
isLoggedIn - Whether user is a logged in
result - outcome basket SUCCESS | INVALID | ERROR
type - Type of the interaction, e.g. item-removed, item-added, etc.
application - SHOP, SALESTOOL, application which is sending the event as the basket can be used by several applications, e.g. Shop, Mobile App, Agent Sales Tool, etc.
basketId - identifier for the basket (token)
id - Technical identifier for the item, basket or voucher, can be string, number
name - name of the item
detail - Can contain any type of detail on the item or voucher, e.g, for a domain this could contain the domain name, for a server, this could contain configuration like data centre, ram and hdd size, etc.
quantity - Quantity of the item
campaign - Identifier of the sales campaign
recurringPrice - Recurring price of the item
discountRecurringPrice - Discounted recurring price of the item
discountTerm - Discounted term in months
setupPrice - Setup price of the item
discountSetupPrice - Discounted setup price of the item
currency - Currency code e.g. EUR, USD, PLN
contractTerm - Contract term in months
billingTerm - Billing term in months
basket - JSON stringified basket
basketId - identifier for the basket (token)
voucherType - Type of the voucher, could be ABSOLUTE, PERCENTAGE, etc.
voucherDiscount - Deduction by the voucher, depending on type. Could be 20 for 20% or 20 for 20 EUR
voucherDuration - Duration of the deduction in months
*/

export type OAOAddItem = {
  isLoggedIn?: boolean
  result: OAOBasketResult
  application?: string
  basketId?: string | number
  id: string | number
  name: string
  detail?: string
  quantity?: number
  campaign?: string
  recurringPrice?: number
  discountRecurringPrice?: number
  discountTerm?: number
  setupPrice?: number
  discountSetupPrice?: number
  currency: string
  contractTerm?: number
  basket?: CardItem[]
}

export type OAORemoveItem = {
  isLoggedIn?: boolean
  result: OAOBasketResult
  application?: string
  basketId?: string | number
  id: string | number
  name?: string
  detail?: string
  quantity?: number
  campaign?: string
  recurringPrice?: number
  discountRecurringPrice?: number
  discountTerm?: number
  setupPrice?: number
  discountSetupPrice?: number
  currency?: string
  contractTerm?: number
  basket?: BasketProductsPE[]
}

export type OAOChangeQuantity = {
  isLoggedIn?: boolean
  result: OAOBasketResult
  application?: string
  basketId?: string | number
  id: string | number
  name?: string
  detail?: string
  quantity: number
  campaign?: string
  recurringPrice?: number
  discountRecurringPrice?: number
  discountTerm?: number
  setupPrice?: number
  discountSetupPrice?: number
  currency?: string
  contractTerm?: number
  basket?: CardItem[]
}

export type OAOChangeTerm = {
  isLoggedIn?: boolean
  result: OAOBasketResult
  application?: string
  basketId?: string | number
  id: string | number
  name?: string
  detail?: string
  quantity?: number
  campaign?: string
  recurringPrice?: number
  discountRecurringPrice?: number
  discountTerm?: number
  setupPrice?: number
  discountSetupPrice?: number
  currency?: string
  contractTerm: number
  billingTerm: number
  basket?: CardItem[]
}

export type OAOAddVoucher = {
  isLoggedIn?: boolean
  result: OAOBasketResult
  application?: string
  id: string | number
  name: string
  detail: string
  voucherType: OAOVoucherType
  voucherDiscount: number
  voucherDuration: number
}

export type OAORemoveVoucher = {
  isLoggedIn?: boolean
  result: OAOBasketResult
  application?: string
  id: string | number
  name: string
  detail: string
}

export type OAOEmptyOrOrderBasket = {
  isLoggedIn?: boolean
  result: OAOBasketResult
  application?: string
  basketId?: string | number
  basket?: CardItem[]
}

declare global {
  // OAO can be undefined on some brands when GATSBY_OAO_TENANT is not set
  interface Window {
    OAO?: {
      q: {
        c: any[]
        t: any[]
      }
    }
  }
}

export function initOaO(): void {
  if (!window.OAO) {
    log("missing", "oao")

    return
  }
  log("starting", "oao")

  window.OAO.q.t.push(["setUserConsent", true])
  log(["setUserConsent", true], "oao")
}

export function pushPageView(): void {
  if (!window.OAO) {
    return
  }

  window.OAO.q.t.push(["pageView"])
  log("pageView", "oao")
}

export function pushOAOBaskeItem(
  basketEventType: OAOEventType,
  basketItemData: OAOBasketItem
): void {
  if (!window.OAO) {
    return
  }

  const basketItem: OAOBasketItem = {
    application: OAOApplicationType.SALESTOOL,
    ...basketItemData,
  }

  window.OAO.q.t.push([basketEventType, basketItem])
  log([basketEventType, basketItem], "oao")
}

export function pushOAOChangeTerm(
  result: BasketResponse,
  basketItem: BasketUpdateItem,
  type?: BasketActions
): void {
  if (type === BasketActions.BASKET_CHANGE_TERM) {
    const periodName = getPeriodNameFromPeriodId(result, basketItem.planPeriod)
    const periodMonths = convertToMonths(periodName) | 0
    const eventData: OAOChangeTerm = {
      result: OAOBasketResult.SUCCESS,
      id: basketItem.id,
      contractTerm: periodMonths,
      billingTerm: periodMonths,
    }

    pushOAOBaskeItem(OAOEventType.CHANGE_TERM, eventData)
  }
}

export function pushOAOChangeQuantity(
  quantity: number,
  productName?: string
): void {
  const eventData: OAOChangeQuantity = {
    result: OAOBasketResult.SUCCESS,
    id: productName || "",
    quantity: quantity,
  }

  pushOAOBaskeItem(OAOEventType.CHANGE_QUANTITY, eventData)
}

export function pushOAOBasketOrderItem(basketItems: CardItem[]): void {
  const eventData: OAOEmptyOrOrderBasket = {
    result: OAOBasketResult.SUCCESS,
    basket: basketItems,
  }
  pushOAOBaskeItem(OAOEventType.ORDER_BASKET, eventData)
}

export function pushOAOEmptyBasket(cartId: string, items: CardItem[]): void {
  const eventData: OAOEmptyOrOrderBasket = {
    result: OAOBasketResult.SUCCESS,
    basketId: cartId,
    basket: items,
  }

  pushOAOBaskeItem(OAOEventType.EMPTY_BASKET, eventData)
}

export function pushOAORemoveItem(
  cartId: string,
  id: number,
  items: BasketProductItem[]
): void {
  const eventData: OAORemoveItem = {
    result: OAOBasketResult.SUCCESS,
    basketId: cartId,
    id: id,
    basket: items.map(getBasketProductsPE),
  }

  pushOAOBaskeItem(OAOEventType.REMOVE_ITEM, eventData)
}

export function pushOAOAddItem(
  items: CardItem[],
  basketId: string | number | null
): void {
  const eventData: OAOAddItem = {
    result: OAOBasketResult.SUCCESS,
    basketId: basketId || "",
    id: !isEmpty(items[0]) ? items[0].item_id : "",
    name: !isEmpty(items[0]) ? items[0].item_name : "",
    recurringPrice: !isEmpty(items[0]) ? items[0].price : undefined,
    currency: getCurrencyCode(),
    basket: items,
  }

  pushOAOBaskeItem(OAOEventType.ADD_ITEM, eventData)
}

export function pushOAOAddVoucher(token: string, result: BasketResponse): void {
  const eventData: OAOAddVoucher = {
    result: OAOBasketResult.SUCCESS,
    id: token,
    name: result.promo_code,
    detail: result.status,
    voucherType: OAOVoucherType.PERCENTAGE,
    voucherDiscount: result.savings,
    voucherDuration: 1,
  } as OAOAddVoucher

  pushOAOBaskeItem(OAOEventType.ADD_VOUCHER, eventData)
}

export function pushOAORemoveVoucher(token: string, status: string): void {
  const eventData: OAORemoveVoucher = {
    result: OAOBasketResult.SUCCESS,
    id: token,
    name: "promo_code_removed",
    detail: status,
  }

  pushOAOBaskeItem(OAOEventType.REMOVE_VOUCHER, eventData)
}
