import type { ReactElement, ReactNode } from "react"
import React, { useContext } from "react"
import _t from "@core/i18n"
import type { PeriodInfo } from "@core/period-info"
import type { PriceType } from "@core/types"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { PriceMolProps } from "@onestore/hel/dist/components/molecules/PriceMol"
import PriceMol from "@onestore/hel/dist/components/molecules/PriceMol"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import { zIndex } from "@onestore/hel/dist/settings"
import HasAnyPromotionContext from "~/context/HasAnyPromotionContext"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { PriceInfo } from "~/fragments/priceInfo"
import isEmpty from "~/lib/isEmpty"

export type DetailedPriceProps = {
  variant: PriceMolProps["variant"]
  periodInfo: PeriodInfo
  customRenewPriceTexts?: string[]
  priceInfo?: PriceInfo
  hasHiddenBadge?: boolean
  showPriceInfoLabelsOnlyOnDefaultPeriod?: boolean
}

export const getMonthlyPriceElement = (
  price: string,
  currentPriceType: PriceType,
  prefix?: string
) => {
  const prefixText = !isEmpty(prefix) ? prefix : _t("prices.monthlyPricePrefix")
  const suffixText = _t("prices.monthlyPricePeriod")

  return (
    <>
      {prefixText}{" "}
      <TextAtm hasFitLineHeight isBold>
        {`${price} ${_t(`prices.${currentPriceType}`)} ${suffixText}`}
      </TextAtm>
    </>
  )
}

export const getAdditionalPriceInformation = (
  priceInfo: PriceInfo,
  currentPriceType: PriceType,
  renewPrice?: string,
  periodType?: number | null,
  periodLength?: number,
  customAdditionalInfo?: string[],
  hidePriceInfoLabels?: boolean
): ReactNode[] => {
  const additionalInformations: ReactNode[] = []

  if (
    priceInfo.hasRenewalPriceText &&
    isEmpty(customAdditionalInfo) &&
    !isEmpty(renewPrice)
  ) {
    additionalInformations.push(
      `${_t("prices.renewalPrefix")} ${renewPrice} ${_t(`prices.${currentPriceType}`)} ${_t(
        `periodName.renewPrice.${periodType}`,
        {
          smart_count: periodLength,
        }
      )}`
    )
  }

  if (priceInfo.hasRenewalPriceText && !isEmpty(customAdditionalInfo)) {
    additionalInformations.push(...customAdditionalInfo)
  }

  if (!hidePriceInfoLabels && !isEmpty(priceInfo?.labels)) {
    const labels: ReactNode[] =
      priceInfo?.labels.map((label): ReactNode => {
        if (!isEmpty(label.flatData.tooltip)) {
          return (
            <TooltipMol
              iconSize="small"
              zIndex={zIndex.FloatingAside}
              labelText={label.flatData.text}
              labelEmphasis="medium"
              labelTypography="nano1"
              hasHoverOnLabel
            >
              {label.flatData.tooltip}
            </TooltipMol>
          )
        }

        return label.flatData.text
      }) || []

    additionalInformations.push(...labels)
  }

  return additionalInformations
}

const DetailedPrice = ({
  variant,
  periodInfo,
  customRenewPriceTexts,
  priceInfo,
  hasHiddenBadge,
  showPriceInfoLabelsOnlyOnDefaultPeriod,
}: DetailedPriceProps): ReactElement<DetailedPriceProps> => {
  const { currentPriceType, isNettoPriceType } = usePriceTypeContext()
  const hasPromotion = periodInfo.hasPromotion()
  const hasAnyPromotion = useContext(HasAnyPromotionContext)
  const isDefaultPeriod = periodInfo.getPeriodObject().default

  const priceMonthly = isNettoPriceType
    ? periodInfo.getMonthlyPriceValue()?.netto
    : periodInfo.getMonthlyPriceValue()?.gross

  const {
    priceValue,
    oldPriceValue,
    promotionPercent,
    lowestPrice,
    lowestPricePercent,
    renewPrice,
  } = periodInfo.usePrices(currentPriceType)

  const priceSuffix = `${_t(`prices.${currentPriceType}`)} ${periodInfo.periodText(false, true)}`

  const additionalPriceInformation = !isEmpty(priceInfo)
    ? getAdditionalPriceInformation(
        priceInfo,
        currentPriceType,
        renewPrice,
        periodInfo.getPeriodType(),
        periodInfo.getPeriodLength(),
        customRenewPriceTexts,
        showPriceInfoLabelsOnlyOnDefaultPeriod && !isDefaultPeriod
      )
    : []

  const priceMonthlyElement = !isEmpty(priceMonthly)
    ? getMonthlyPriceElement(
        priceMonthly,
        currentPriceType,
        priceInfo?.monthlyPricePrefix
      )
    : null

  return (
    <PriceMol
      variant={variant}
      hasCrossedPriceSafeSpace={!!hasAnyPromotion && !hasPromotion}
      mainPrice={{
        currentPrice: priceValue,
        crossedPrice: hasPromotion ? oldPriceValue : undefined,
        periodText: priceSuffix,
      }}
      badgeText={
        !hasHiddenBadge && priceInfo?.hasMonthlyPriceText
          ? priceInfo?.customMonthlyPriceText || priceMonthlyElement
          : null
      }
      additionalInformation={additionalPriceInformation}
      regularPrice={
        hasPromotion
          ? {
              text: _t("prices.regularPriceWithType", {
                priceType: _t(`prices.${currentPriceType}`),
              }),
              price: oldPriceValue,
              percent: promotionPercent,
            }
          : undefined
      }
      lowestPrice={
        hasPromotion
          ? {
              text: _t("prices.lowestPriceWithType", {
                priceType: _t(`prices.${currentPriceType}`),
              }),
              price: lowestPrice,
              percent: lowestPricePercent,
            }
          : undefined
      }
    />
  )
}

export default DetailedPrice
