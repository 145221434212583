import React, { createContext, useContext } from "react"
import type { ReactElement } from "react"
import HorizontalTableMol from "@onestore/hel/dist/components/molecules/HorizontalTableMol"
import ProductCategoryTabsMol from "@onestore/hel/dist/components/molecules/ProductCategoryTabsMol/ProductCategoryTabsMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import type { JustifyContent } from "@onestore/hel/dist/components/organisms/FlexContainerOrg/FlexContainerOrg.types"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import type { Column } from "@gatsby-plugin-generic-page/fragments/tableHeader"
import type { TableHorizontalBoxesSectionFlatData } from "@gatsby-plugin-generic-page/fragments/tableHorizontalBoxesSection"
import useHorizontalTableFilter from "@gatsby-plugin-generic-page/hooks/useHorizontalTableFilter"
import useHorizontalTableSort from "@gatsby-plugin-generic-page/hooks/useHorizontalTableSort"
import type { ConfigsHookResult } from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import useTableConfigs from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import TogglePriceType from "~/components/TogglePriceType"
import isEmpty from "~/lib/isEmpty"
import ConfigBoxes from "./components/ConfigBoxes/ConfigBoxes"
import FiltersLabels from "./components/FiltersLabels"
import TableRow from "./components/TableRow/TableRow"

export const ConfigsHookContext = createContext<ConfigsHookResult | null>(null)

export const useConfigsHookContext = () => useContext(ConfigsHookContext)

export default function TableHorizontalBoxesSection({
  configs,
  header,
  categoryTabs,
  products,
  tableBackgroundColor,
  sectionSettings,
}: TableHorizontalBoxesSectionFlatData): ReactElement<TableHorizontalBoxesSectionFlatData> {
  const configsHookResult = useTableConfigs(configs, products)

  const { filteredProducts, activeIndex, handleTabItemClick } =
    useHorizontalTableFilter(categoryTabs, products)

  const {
    activeSortedColumnName,
    activeSortedStatus,
    sortedProducts,
    columnSortClik,
  } = useHorizontalTableSort(header, filteredProducts)

  const configsElements = !isEmpty(configs) ? (
    <ConfigBoxes configs={configs} />
  ) : null

  const tableBar = {
    columns: header.columns.map((column: Column, index) => ({
      title: column.label,
      widthPercent: column.widthPercent,
      align:
        index === header.columns.length - 1
          ? ("right" as JustifyContent)
          : ("left" as JustifyContent),
      sortData: {
        isEnabled: column.sortEnabled ?? false,
        status:
          column.label === activeSortedColumnName ? activeSortedStatus : null,
        onClick: column.sortEnabled
          ? () => columnSortClik(column.label)
          : undefined,
      },
    })),
  }

  const actualProducts = !isEmpty(sortedProducts)
    ? sortedProducts
    : filteredProducts

  const rowsElements = (
    <GridOrg columns={1} gutterSpace={3}>
      {actualProducts.map((product, index) => (
        <TableRow
          key={`${product.id}-${index}`}
          header={header}
          productData={product}
        />
      ))}
    </GridOrg>
  )

  const tabsElement = !isEmpty(categoryTabs)
    ? categoryTabs.map((tab, index) => ({
        ...tab,
        title: tab.flatData.title,
        outlineColor: tab.flatData.borderColor,
        text: tab.flatData.text,
        icon:
          tab.flatData.icon && !isEmpty(tab.flatData.icon[0]) ? (
            <Picture srcData={tab.flatData.icon[0].url} />
          ) : null,
        onClick: (event) => {
          handleTabItemClick(event, index)
        },
      }))
    : []

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <PushOrg bottomSpace={isEmpty(categoryTabs) ? 3 : 0} hasNoReset>
          {!isEmpty(categoryTabs) ? (
            <>
              <BreakpointVisibilityOrg from="small" to="large">
                <PushOrg topSpace={2} bottomSpace={3} rightSpace={2} hasNoReset>
                  <AlignContentOrg horizontalAlign="right">
                    <TogglePriceType />
                  </AlignContentOrg>
                </PushOrg>
              </BreakpointVisibilityOrg>

              <FlexContainerOrg
                justifyContent="space-between"
                alignItems="center"
                gapSpace={8}
              >
                <FlexItemOrg flexGrow={1} flexShrink={1} flexBasis={0}>
                  <PushOrg
                    bottomSpace={{ small: -3, medium: 0, large: 0 }}
                    hasNoReset
                  >
                    <ProductCategoryTabsMol
                      tabs={tabsElement}
                      activeTabIndex={activeIndex}
                    />
                  </PushOrg>
                </FlexItemOrg>

                <BreakpointVisibilityOrg from="large">
                  <WidthOrg widthSpace={20}>
                    <PushOrg bottomSpace={3} hasNoReset>
                      <TogglePriceType />
                    </PushOrg>
                  </WidthOrg>
                </BreakpointVisibilityOrg>
              </FlexContainerOrg>
            </>
          ) : (
            <PushOrg rightSpace={{ small: 3, medium: 3, large: 0 }} hasNoReset>
              <AlignContentOrg
                horizontalAlign="right"
                horizontalAlignType="flex"
              >
                <TogglePriceType />
              </AlignContentOrg>
            </PushOrg>
          )}
        </PushOrg>

        <SurfaceOrg
          color={
            !isEmpty(tableBackgroundColor)
              ? tableBackgroundColor.background
              : "backgroundLight"
          }
          spacingSpace={{
            small: 3,
            medium: 3,
            large: 6,
          }}
          borderRadiusSize={{
            small: isEmpty(categoryTabs) ? "xlarge" : "none",
            medium: "xlarge",
            large: "xlarge",
            huge: "xlarge",
          }}
          shadow="medium"
        >
          <ConfigsHookContext.Provider value={configsHookResult}>
            <HorizontalTableMol
              configs={configsElements}
              activeFilters={<FiltersLabels />}
              tableBar={tableBar}
              rows={rowsElements}
            />
          </ConfigsHookContext.Provider>
        </SurfaceOrg>
      </BaseContainer>
    </BaseSection>
  )
}
